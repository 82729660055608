import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { SUPPORTED_LOCALES } from '../../constants';

// All of these methods should be used instead of provided by nextjs.
export const {
  Link: NextIntlLink,
  redirect,
  usePathname,
  useRouter,
} = createSharedPathnamesNavigation({
  locales: SUPPORTED_LOCALES,
});
