'use client';
import clsx from 'clsx';
import * as React from 'react';

import { NextIntlLink } from '../navigation';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  href: string;
  scroll?: boolean;
  replace?: boolean;
}

export const Link = React.forwardRef<HTMLAnchorElement, Props>((props: Props, ref) => {
  const { className, children, href, scroll, replace, ...rest } = props;
  return (
    <NextIntlLink {...rest} href={href} ref={ref} className={clsx(className)} scroll={scroll} replace={replace}>
      {children}
    </NextIntlLink>
  );
});
